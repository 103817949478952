
import Footer from '@/components/Footer.vue'
import Cloud from '@/components/home/Cloud.vue'
import Coupons from '@/components/home/Coupons.vue';
import Project from '@/components/cloud/Project.vue';
import { useRoute } from "vue-router";
import { ref } from "vue";
export default {
  components:{
    Footer,
    Cloud,
    Coupons,
    Project
  },
  setup(){
    const router = useRoute();
    const projectId=Number(router.params?.id);
    const project=ref({name:'',instructions:''});
    const list=ref();
    fetch("/static/data.json")
      .then((response) => response.json())
      .then((data) => {
        const item=data.find((res:any)=>res.id==projectId);
        if(item){
          project.value=item;
          list.value=item.projects_type;
        }
      });
    return {
      project,
      list
    }
  }

}
