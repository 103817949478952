<template>
  <div class="container footer-placeholder">
    <div class="banner-box">
      <h3>{{project.name}}</h3>
      <p>{{project.instructions}}</p>
    </div>
    <Cloud/>
    <div class="coupons-box">
      <h3>阿里云全线产品年中限时最低价</h3>
      <button>现在咨询，获得更多折扣<span></span></button>
      <Coupons/>
    </div>
    <Project v-for="(item,index) in list" :key="index" :item="item"/>
  </div>
  <Footer/>
</template>
<script lang="ts">
import Footer from '@/components/Footer.vue'
import Cloud from '@/components/home/Cloud.vue'
import Coupons from '@/components/home/Coupons.vue';
import Project from '@/components/cloud/Project.vue';
import { useRoute } from "vue-router";
import { ref } from "vue";
export default {
  components:{
    Footer,
    Cloud,
    Coupons,
    Project
  },
  setup(){
    const router = useRoute();
    const projectId=Number(router.params?.id);
    const project=ref({name:'',instructions:''});
    const list=ref();
    fetch("/static/data.json")
      .then((response) => response.json())
      .then((data) => {
        const item=data.find((res:any)=>res.id==projectId);
        if(item){
          project.value=item;
          list.value=item.projects_type;
        }
      });
    return {
      project,
      list
    }
  }

}
</script>
<style lang="less" scoped>
.banner-box{
  padding: 50px 40px;
  background:url(/static/img/ali-detail.jpg) no-repeat center;
  background-size: cover;
  color: #fff;
  >h3{
    font-size: 22px;
    font-weight: bold;
  }
  >p{
    font-size: 14px;
    font-weight: 400;
    line-height: 1.8;
  }
}
.coupons-box{
  border-top: 1px solid #DDDDDD;
  padding: 20px 0 0;
  text-align: center;
  >h3{
    font-size: 20px;
    font-weight: bold;
    color: #383838;
  }
  >button{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    background: #F23D49;
    border: 0 none;
    border-radius: 3px;
    margin: 20px auto 25px;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    padding: 0 10px;
    >span{
      position: relative;
      padding-right: 12px;
      &::after,&::before{
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        width: 8px;
        height: 1px;
        background-color: #fff;
      }
      &::after{
        transform: rotate(45deg) translateY(-4px);
      }
      &::before{
        transform: rotate(-45deg) translateY(4px);
      }
    }
  }
}
</style>