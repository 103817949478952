<template>
  <div class="cloud">
    <div class="cloud-item">
      <img src="../../assets/index/cloud_1.jpg"/>
      <h3>阿里云</h3>
      <h4>区域能力中心</h4>
      <p>阿里云精英级<br/>授权服务商</p>
    </div>
    <div class="cloud-item">
      <img src="../../assets/index/cloud_2.jpg"/>
      <h3>专属钉钉</h3>
      <h4>区域核心代理商</h4>
      <p>西南首例专属钉钉<br/>交付服务商</p>
    </div>
    <div class="cloud-item">
      <img src="../../assets/index/cloud_3.jpg"/>
      <h3>Teambition</h3>
      <h4>官方授权服务中心</h4>
      <p>西南首例全国百强企业<br/>私有化部署服务商</p>
    </div>
  </div>
</template>
<style lang="less" scoped>
.cloud{
 background-color: #fff;
 display: flex;
 justify-content: space-between;
 padding: 15px 20px;
 .cloud-item{
  color: #383838;
  text-align: center;
  >img{
    width: 38px;
    height: 38px;
    display: block;
    margin: auto;
  }
  >h3{
    font-size: 15px;
    font-weight: bold;
    line-height: 1.8;
  }
  >h4{
    font-size: 12px;
    font-weight: 300;
    line-height: 1.5;
  }
  >p{
    font-size: 11px;
    font-weight: 300;
    line-height: 1.5;
  }
 }
}
</style>
