<template>
  <div class="cloud-back" @click="openService">
    <div class="ticket"></div>
    <div class="txt">联系客服领取全场通用优惠券</div>
  </div>
</template>
<style lang="less" scoped>
.cloud-back{
  width: 100vw;
  height: 48.133vw;
  padding: 5.33vw 6.66vw 13vw 45vw;
  background: url(/static/img/coup_back_1.png) no-repeat center;
  background-size: cover;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  .ticket{
    width: 39.86vw;
    height: 16.133vw;
    background: url(../../assets/index/back_2.png) no-repeat center;
    background-size: cover;
  }
  .txt{
    font-size: 11px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #7E1F1F;
    text-align: center;
    margin-top: 1vw;
  }
}
</style>